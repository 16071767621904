import React from "react";
import {Box, Button, Container} from "@mui/material";

export const BoxButtons = ({handleSetPartecipa}) => {
  return(
      <Container maxWidth={"xs"} sx={{textAlign:'center', display:'flex', flexDirection:'column'}}>
          <Box style={{width:'100%'}}>
              <Button onClick={()=>handleSetPartecipa(true)} sx={{width:'100%'}} size={"large"} variant={"contained"}>
                  Partecipo
              </Button>
          </Box>
          <Box>
              <Button onClick={()=>handleSetPartecipa(false)} sx={{mt:2,width:'100%', maxWidth:'20rem'}} size={"medium"} variant={'outlined'}>
                  Non partecipo
              </Button>
          </Box>
      </Container>
  )
}
