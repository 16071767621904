import React, {useState} from "react";
import {
    Box,
    Button, Checkbox,
    Container,
    FormControl, FormControlLabel,
    FormLabel,
    Grid, Radio,
    RadioGroup,
    TextareaAutosize,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import axios from "axios";

export const FormPartecipa = ({
                                  dati,
                                  handleSetSending,
                                  handleSetError,
                                  handleSetSuccess,
                                  success,
                                  handleSetMessage,
                                  handleSetPrivacyError
                              }) => {
    const {hash} = useParams()
    const [intolleranze, setIntolleranze] = useState('')
    const [accommodation, setAccommodation] = useState({
        pernottamento1: '',
        pernottamento2: '',
        parcheggio: ''
    })

    const [privacy, setPrivacy] = useState(false)

    const checkContent = [
        {
            key: 'pernottamento1',
            label: '1. Abiti fuori Milano e Provincia e hai necessità di pernottare a Milano la notte di Mercoledì 13 Luglio (solo per chi è stato convocato per i meeting di area che si svolgeranno il 14 mattina. Eventuali eccezioni saranno valutate dalle direzioni)?',
            value: accommodation.pernottamento1
        },
        {
            key: 'pernottamento2',
            label: '2. Abiti fuori Milano e Provincia e hai necessità di pernottare a Milano la notte di Giovedì 14 Luglio (eventuali eccezioni saranno valutate dalle direzioni)?',
            value: accommodation.pernottamento2
        },
        {
            key: 'parcheggio',
            label: '3. Hai bisogno del parcheggio? All’interno della struttura è disponibile un parcheggio illimitato facilmente raggiungibile direttamente dalla location.',
            value: accommodation.parcheggio
        }
    ]

    const handleSetAccommodation = (e, key) => {
        setAccommodation(prev => ({...prev, [key]: e.target.value}))
    }

    const handleChange = (e) => {
        setIntolleranze(e.target.value)
    }

    const sendData = async (e) => {
        e.preventDefault()
        handleSetSending(true)
        if (!privacy) {
            handleSetPrivacyError(true)
        } else {
            const data = {
                email: dati.email,
                intolleranze,
                hash,
                partecipa: true,
                accommodation
            }
            await axios.post('https://api-e-day-2022-2pbtg6lkba-ey.a.run.app/register/', data)
                .then(handleSetSuccess)
                .catch(e => {
                        handleSetError()
                        handleSetMessage(e.response.data.error ? e.response.data.error : "C'è stato un errore, si prega di riprovare")
                    }
                )
        }
        handleSetSending(false)
    }

    return (
        <Container>
            <form onSubmit={sendData}>
                <Grid container sx={{width: '100%'}} direction={"column"}>
                    {Object.keys(dati).map(k =>
                        <Grid item sx={{mb: 1}} key={k}>
                            <Typography>
                                <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{k}:</span> {dati[k]}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{mb: 1}}>
                        <Typography fontWeight={"bold"}>
                            Intolleranze/Allergie:
                        </Typography>
                        <TextareaAutosize onChange={handleChange}
                                          style={{resize: 'none', width: '100%', fontFamily: 'inherit'}} minRows={4}/>
                    </Grid>
                    <Typography fontWeight={'bold'}>
                        INFORMAZIONI LOGISTICHE da compilare obbligatoriamente
                    </Typography>
                    {checkContent.map(c =>
                        <Grid item xs={12} key={c.label}>
                            <FormControl>
                                <FormLabel id={c.label}>{c.label}</FormLabel>
                                <RadioGroup
                                    row
                                    name={c.label}
                                    onChange={(event) => handleSetAccommodation(event, c.key)}
                                >
                                    <FormControlLabel value="si" control={<Radio required/>} label="Si"/>
                                    <FormControlLabel value="no" control={<Radio required/>} label="No"/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                    <Typography sx={{mt: 2}}>
                        N.B.
                        <br/>
                        Nel caso tu abbia richiesto il pernottamento riceverai indicazioni successive.
                        <br/>
                        Per gli <b>spostamenti</b> organizzati come fai di consueto con le trasferte.
                        <br/>
                        Ti ricordiamo che è disponibile il portale Egencia.
                        <br/>
                        Per maggiore supporto, contatta la Segreteria dell’evento scrivendo a <a
                        style={{fontWeight: 'bold'}} href={'mailto:segreteria@eday2022.it'}>segreteria@eday2022.it</a>
                        <br/>
                        <br/>
                    </Typography>
                </Grid>
                <FormControlLabel
                    label={<div>Accetto la <a href={'/privacy.pdf'} target={'_blank'}>privacy policy</a></div>}
                    control={
                        <Checkbox
                            checked={privacy}
                            onChange={(event) => setPrivacy(!privacy)}
                        />
                    }
                />
                <Box style={{width: '100%', textAlign: 'center'}} sx={{mt: 2}}>
                    {success ?
                        <Typography color={'green'} fontWeight={'bold'}>Registrazione avvenuta con successo</Typography>
                        : <Button variant={"contained"} type='submit'>
                            Confermo
                        </Button>}
                </Box>
            </form>
        </Container>
    )
}
