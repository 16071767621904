import React, {useEffect, useState} from "react";
import {Backdrop, Box, CircularProgress, Snackbar} from "@mui/material";
import {Header} from "../components/Header";
import {BoxButtons} from "../components/BoxButtons";
import {FormContainer} from "../components/FormContainer";
import MuiAlert from '@mui/material/Alert';
import {AlreadyRegistered} from "../components/AlreadyRegistered";
import axios from "axios";
import {useParams} from "react-router-dom";
import {WrongHash} from "../components/WrongHash";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Home = () => {
    const {hash} = useParams()
    const [dati, setDati] = useState({
        nome: '',
        cognome: '',
        email: ''
    })
    const [partecipa, setPartecipa] = useState(null)
    const [loading, setLoading] = useState(true)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [registered, setRegistered] = useState(false)
    const [wrongHash, setWrongHash] = useState(true)

    useEffect(() => {
        getData().then()
    }, [])

    const getData = async () => {
        setLoading(true)
        await axios.get('https://api-e-day-2022-2pbtg6lkba-ey.a.run.app/user/' + hash)
            .then(response => {
                setWrongHash(false)
                setDati({nome: response.data.nome, cognome: response.data.cognome, email: response.data.email})
            })
            .catch(() => setWrongHash(true))
        setLoading(false)
    }
    const handleSetPartecipa = (bool) => {
        setPartecipa(bool)
    }

    const handleSetSending = (bool) => {
        setSending(bool)
    }

    const handleSetSuccess = () => {
        setSuccess(true)
    }

    const handleSetError = () => {
        setError(true)
    }

    const handleSetMessage = (messaggio) => {
        setMessage(messaggio)
    }

    return (
        <Box>
            <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading || sending}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Header/>
            {!loading &&
                (wrongHash ?
                    <WrongHash/> :
                    registered ?
                        <AlreadyRegistered/>
                        : <Box sx={{my: 3}}>
                            {partecipa === null ?
                                <BoxButtons handleSetPartecipa={handleSetPartecipa}/>
                                : <FormContainer handleSetPartecipa={handleSetPartecipa} handleSetSending={handleSetSending}
                                                 partecipa={partecipa} dati={dati} success={success}
                                                 handleSetError={handleSetError} handleSetSuccess={handleSetSuccess}
                                                 handleSetMessage={handleSetMessage}
                                />
                            }
                        </Box>)
            }
        </Box>
    )
}
