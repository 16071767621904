import React, {useState} from "react";
import {Button, Container, Snackbar} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {FormPartecipa} from "./FormPartecipa";
import {FormNonPartecipa} from "./FormNonPartecipa";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const FormContainer = ({partecipa, handleSetPartecipa, dati, handleSetSending, success, handleSetError, handleSetSuccess, handleSetMessage}) => {
    const [privacyError, setPrivacyError] = useState(false)

    const handleSetPrivacyError = (bool) => {
        setPrivacyError(bool)
    }

  return(
      <Container maxWidth={"sm"}>
          <Snackbar open={privacyError} autoHideDuration={6000} onClose={()  => handleSetPrivacyError(false)}>
              <Alert onClose={()  => handleSetPrivacyError(false)} severity="error" sx={{ width: '100%' }}>
                  Per favore accetta la privacy
              </Alert>
          </Snackbar>
          {!success && <Button onClick={() => handleSetPartecipa(null)} startIcon={<ArrowBackIcon/>}>Indietro</Button>}
          {!partecipa && <FormNonPartecipa dati={dati} handleSetPrivacyError={handleSetPrivacyError} handleSetMessage={handleSetMessage} handleSetSending={handleSetSending} success={success} handleSetError={handleSetError} handleSetSuccess={handleSetSuccess}/>}
          {partecipa && <FormPartecipa dati={dati} handleSetPrivacyError={handleSetPrivacyError} handleSetMessage={handleSetMessage} handleSetSending={handleSetSending} success={success} handleSetError={handleSetError} handleSetSuccess={handleSetSuccess}/>}
      </Container>
  )
}
