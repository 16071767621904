import React from 'react';
import videojs from 'video.js';
import VideoJS from '../components/VideoJS'
import {Box, Container} from "@mui/material";

const Live = () => {
    const playerRef = React.useRef(null);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        techOrder: ["AmazonIVS"],
        poster: 'poster.png'
    };

    const streamUrl = 'https://ebace1163120.eu-central-1.playback.live-video.net/api/video/v1/eu-central-1.887458473138.channel.3NA89CUet4Gb.m3u8';

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    return (
        <Box style={{background: 'url(bg.jpg)', backgroundSize: 'cover'}}>
            <Container style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} streamUrl={streamUrl}/>
            </Container>
        </Box>
    );
}

export default Live;
