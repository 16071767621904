import React from "react";
import {Container, Typography} from "@mui/material";

export const WrongHash = () => {
  return(
      <Container maxWidth={"sm"} sx={{textAlign:'center'}}>
        <Typography>
          <b style={{color:'red', fontSize:'1.3rem'}}>Il link è errato.</b>
          <br/>
          Assicurati di aver cliccato il link corretto, se il problema persiste prova a copiare ed incollare il link direttamente dalla mail.
          In caso di ulteriori problemi contatta la Segreteria dell’evento scrivendo a <a style={{fontWeight:'bold'}} href={'mailto:segreteria@eday2022.it'}>segreteria@eday2022.it</a>
        </Typography>
      </Container>
  )
}
