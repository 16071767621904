import React from "react";
import {Box, Container} from "@mui/material";

export const QrComponent = ({dati}) => {
    console.log(dati.qrcode)
    return (
        <Container maxWidth={"sm"}>
            <Box>
                <p>Nome: {dati.nome}<br/>Cognome: {dati.cognome}<br/>Email: {dati.email}</p>
            </Box>
            <Box sx={{width:'100%', textAlign:'center', height:'13rem'}}>
                <p style={{margin:0}}>Il tuo biglietto:</p>
                <img style={{height:'100%'}} src={dati.qrcode}/>
            </Box>
        </Container>
    )
}
