import React, {useState} from "react";
import {
    Box,
    FormLabel,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Button, Container, Checkbox
} from "@mui/material";
import {useParams} from "react-router-dom";
import axios from 'axios'

export const FormNonPartecipa = ({
                                     dati,
                                     handleSetSending,
                                     success,
                                     handleSetError,
                                     handleSetSuccess,
                                     handleSetMessage,
                                     handleSetPrivacyError
                                 }) => {
    const {hash} = useParams()
    const [motivazione, setMotivazione] = useState(null);
    const [altro, setAltro] = useState('')
    const [privacy, setPrivacy] = useState(false)

    const handleChange = (event) => {
        setMotivazione(event.target.value);
    };

    const sendData = async () => {
        handleSetSending(true)
        if (!privacy) {
            handleSetPrivacyError(true)
        } else {
            const data = {
                email: dati.email,
                motivazione,
                hash,
                partecipa: false
            }
            await axios.post('https://api-e-day-2022-2pbtg6lkba-ey.a.run.app/register/', data)
                .then(handleSetSuccess)
                .catch(e => {
                        handleSetError()
                        handleSetMessage(e.response.data.error)
                    }
                )
        }
        handleSetSending(false)
    }

    return (
        <Container>
            <Typography fontWeight={"bold"} variant={"h6"}>Ti chiediamo di indicare la motivazione:</Typography>
            <FormControl>
                <RadioGroup
                    value={motivazione}
                    onChange={handleChange}
                >
                    <FormControlLabel value="Per precedenti impegni di lavoro" control={<Radio/>}
                                      label="Impegni precedenti di lavoro preventivamente autorizzati dal mio responsabile"/>
                    <FormControlLabel value="Ferie" control={<Radio/>}
                                      label="Ferie preventivamente autorizzate dal mio responsabile"/>
                    <FormControlLabel value='Altro' control={<Radio/>}
                                      label='Altro: infortunio, malattia, congedi…'
                    />
                </RadioGroup>
            </FormControl>
            <FormControlLabel
                label={<div>Accetto la <a href={'/privacy.pdf'} target={'_blank'}>privacy policy</a></div>}
                control={
                    <Checkbox
                        checked={privacy}
                        onChange={(event) => setPrivacy(!privacy)}
                    />
                }
            />
            <Box style={{width: '100%', textAlign: 'center'}} sx={{my: 2}}>
                {success ?
                    <Typography color={'green'} fontWeight={'bold'}>Dati inviati con successo.<br/>Ci spiace che tu non
                        possa partecipare! Arrivederci a presto.</Typography>
                    : <Button disabled={motivazione === null || motivazione === ''} variant={"contained"}
                              onClick={sendData}>
                        Confermo
                    </Button>}
            </Box>
        </Container>
    )
}
