import React from "react";
import {Box, Container, Typography} from "@mui/material";

export const AlreadyRegistered = () => {
  return(
      <Container maxWidth={'sm'} sx={{p:3}}>
          <Typography textAlign={"center"} variant={'h4'} fontWeight={'bold'}>Sei già registrato all'evento</Typography>
          <br/>
          <Typography textAlign={"center"} variant={'h6'}>
              Controlla nella posta in arrivo della tua casella di posta elettronica, troverai il biglietto per partecipare all'evento.
              <br/>
              Se non dovessi trovarlo controlla anche nello spam.
          </Typography>
          <br/>
          <Typography textAlign={"center"} variant={'body1'}>
              In caso di problemi contatta la segreteria organizzativa tramite mail:
              <br/>
              <a href={'mailto:'} target={'_blank'}>segreteria@segreteria.it</a>
              <br/>
              <br/>
              oppure tramite telefono al:
              <br/>
              xxxxx
          </Typography>
      </Container>
  )
}
