import React, {useEffect, useState} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {Header} from "../components/Header";
import {WrongHash} from "../components/WrongHash";
import {QrComponent} from "../components/QrComponent";
import axios from "axios";
import {useParams} from "react-router-dom";

export const QrPage = () => {
    const {hash} = useParams()
    const [loading, setLoading] = useState(false)
    const [wrongHash, setWrongHash] = useState(true)
    const [dati, setDati] = useState({})

    useEffect(() => {
        getData().then()
    }, [])

    const getData = async () => {
        setLoading(true)
        await axios.get('https://api-e-day-2022-2pbtg6lkba-ey.a.run.app/user/' + hash)
            .then(response => {
                setWrongHash(false)
                setDati(response.data)
            })
            .catch(() => setWrongHash(true))
        setLoading(false)
    }
  return(
      <Box>
          <Backdrop
              sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={loading}
          >
              <CircularProgress color="inherit"/>
          </Backdrop>
          <Header/>
          {!loading &&
              (wrongHash ?
                  <WrongHash/> :
                  <QrComponent dati={dati}/>)
          }
      </Box>
  )
}
