import './App.css';
import {Home} from "./pages/Home";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {universalTheme} from "./theme/universalTheme";
import {Route, Routes} from 'react-router-dom'
import {QrPage} from "./pages/QrPage";
import Live from "./pages/Live";

function App() {
    return (
        <ThemeProvider theme={universalTheme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<main/>}/>
                <Route path={'/register/:hash'} element={<Home/>}/>
                <Route path={'/qr/:hash'} element={<QrPage/>}/>
                <Route path={'/live'} element={<Live/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
